import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://4c7d7aa1a6454744b192b5847c35723c@o301017.ingest.sentry.io/6068812',
  sampleRate: 0.7,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
});
