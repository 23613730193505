import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import data from '../../content/spa-content.json';

const initialState = data;

export const planContext = React.createContext();

const Provider = (props) => {
  const { i18n } = useTranslation();
  const currLanguage = i18n.language || 'en';
  const [responses, setResponses] = useState(initialState[currLanguage]);

  const onUpdateResponse = (event, type) => {
    setResponses((prevState) => {
      const steps = prevState.filter((step) => step.type !== type);
      const stepResponses = prevState.find((item) => item.type === type).text;
      const stepType = prevState.find((item) => item.type === type);
      const newStep = { ...stepType, text: [...stepResponses] };
      newStep.text[event.target.name] = event.target.value;
      return [newStep, ...steps];
    });
  };

  const onAddResponse = (type) => {
    setResponses((prevState) => {
      const steps = prevState.filter((step) => step.type !== type);
      const stepResponses = prevState.find((item) => item.type === type).text;
      const stepType = prevState.find((item) => item.type === type);
      const newStep = { ...stepType, text: [...stepResponses, ''] };
      return [newStep, ...steps];
    });
  };

  const onRemoveResponse = (index, type) => {
    setResponses((prevState) => {
      const steps = prevState.filter((step) => step.type !== type);
      const stepResponses = prevState.find(
        (item) => item.type === type,
      ).text.filter((item, j) => index !== j);
      const stepType = prevState.find((item) => item.type === type);
      const newStep = { ...stepType, text: [...stepResponses] };
      return [newStep, ...steps];
    });
  };

  const clearAllResponses = () => {
    setResponses((prevState) => prevState.map((step) => ({ ...step, text: ['', '', ''] })));
  };

  return (
    <planContext.Provider value={{
      responses,
      changeResponse: (e, type) => onUpdateResponse(e, type),
      addResponse: (type) => onAddResponse(type),
      removeResponse: (index, type) => onRemoveResponse(index, type),
      clearAllResponses,
      currentLang: i18n.language,
    }}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </planContext.Provider>
  );
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
